$light_green: #effffa;
$dark_green: #14da8f;
$black: #000000;
$white: #fff;
.dflex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}
.content_wrapper {
  width: 100%;
  margin: 1.5% 0%;
  @extend .dflex;
  justify-content: space-between;
  align-items: center;
  background-color: #081c15;
  
  // clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
  border-radius: 16px;
  padding: 3%;

  @media screen and (max-width: 600px) {
    margin-top: 10%;
    flex-direction: column;
    padding: 20px;
    align-items: center;
  }

  .left_content_wrapper {
    width: 100%;
    text-align: start;

    h2 {
      font-size: 7.5vw;
      font-weight: 600;
      line-height: 1.3;
      overflow: hidden;
      color: var(--font-color);
      span {
        display: inline-block;
        span.second {
          color: var(--yellow-color);
          margin-left: 10px;
        }
      }
      @media screen and (max-width: 600px) {
        margin-top: 8px;
      }
    }
    p {
      font-family: monospace;
      font-optical-sizing: auto;
      font-style: normal;
      font-weight: 500;
      font-size: 3.6vw;
      color: var(--gray-color);
      margin: 25px 0 40px 0;
      @media screen and (max-width: 600px) {
        margin-top: 15%;
        margin-bottom: 7%;
      }
    }
    .btn_group {
      @extend .dflex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      .btn {
        @extend .dflex;
        width: 47%;
        height: 56px;
        font-size: 4vw;
        border-radius: 8px;
        font-weight: 600;
        margin-bottom: 13px;
        &.btn_primary {
          background-color: $black;
          color: $white;
          svg {
            margin-left: 8px;
          }
        }
        &.btn_secondary {
          background-color: $white;
          color: $black;
          border: 1px solid $black;
        }
        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }
    .review_container {
      margin-top: 5px;
      p.more_review {
        color: var(--gray-color);
        font-weight: 500;
        font-size: 3.6vw;
        margin: 5px 0 0;
      }
    }
  }

  @media screen and (min-width: 601px) {
    .left_content_wrapper {
      h2 {
        font-size: 2.8vw;
      }
      p {
        font-size: 1.25vw;
      }
      .btn_group .btn {
        font-size: 1.5vw;
      }
      .review_container {
        p.more_review {
          font-size: 1.25vw;
        }
      }
    }
  }

  .right_content_wrapper {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 600px) {
      justify-content:center;
    }
    img {
      display: block;
      width: 90%;
      border-radius: 15px;
      box-shadow: 0px 0px 20px rgba(12, 12, 12, 0.689);

    }
  }
}
